import React from "react";
import {navigate} from 'gatsby'; //import navigate from gatsby

function MapFR() {
    function zoneChange1() {
        navigate(`/fr/sante/postes/nb-est`);
    }

    function zoneChange2() {
        navigate(`/fr/sante/postes/nb-ouest`);
    }

    function zoneChange3() {
        navigate(`/fr/sante/postes/nb-sud`);
    }

    function zoneChange4() {
        navigate(`/fr/sante/postes/nb-nord`);
    }

    function zoneChange5() {
        navigate(`/fr/sante/postes/nb-centrale`);
    }

    return (
        <svg version="1.1" id="Layer_1" x="0px" y="0px" className={"mwmd p3 mb4"} viewBox="0 0 876 876">
            <g>
                <g onClick={zoneChange1} className={"z1 zone"}>
                    <path class="st0" d="M628.2,485.9l-18.1-37.2l-69.6-6.2l30.3-77.7l95.8-117.5l5.7,12.2l0.1,3.9l-4.7,7.1l-4.5,5.7l-1.8,12.7l-8.5,9.8l9.6,0.5
			l4.1,12.8l2.7-0.8l1.6,2.2l-3.2,5.2l5.6,0.1l9.4,2.2l-5.6,9l-0.8,9.5l0.7,4c-2.4,0.8-7.2,2.5-7.7,3c-0.2,0.2-0.6,0.6,0.5,3.3
			c0.5,1.1,1.2,2.4,1.7,3c-0.4,0.5-2.5,1.6-4.8,2.5l-16.1,7.8l-4.6,21.5l1.4-2.1c0.9-1.3,8.7-12.5,8.7-14.9
			c0.1-1.7,14.4-7.8,15.9-8.1c0.5,0.1,4.1,2.8,6,4.7c0.2,0.2,0.5,0.4,0.8,0.4c1.7,0,2.7-5,3.1-7.9v-0.2l-6.1-8.5l9.8-4.2l-5.9-4.3
			l1.9-9.8l12.7-0.9l5.1-2.1l3,5.9l1.3,8.7l-0.6,5.7l2.3,8.7l5.8,6.1l0.2,0.5l11.4,5.4l8,5.9l-0.5,2.9c-0.3-0.1-0.5-0.3-0.6-0.4
			c0-0.2-0.3-0.8-1.4-2.6l-0.1-0.1l-10.8-6.5l-1.8,5.2c-0.6,0.2-2.1,0.8-4,1.5h-0.1l-1.8,1.8l3.3,2.3c-7.2,6.3-7.8,7.2-7.9,7.5
			c-0.5,1-7.3,10-11.5,15.5l0.7,0.7l15.8-14.8l7.1-2.2l3.3-4.6l2.7,1l1.2,1l4.3,8.3l1.9,14.3l2.7,2.6c-2.2,1.2-5.3,2.9-5.8,3.1
			c-0.5,0.2-3.2,0.5-4.9,0.7h-0.1h-0.1c-9.8,5.1-9.9,5.2-10,5.3l-0.2,0.2l0.1,0.3c0.2,0.4,0.2,0.4,12.4,0.7h0.1l16.3-10l-2.2-3.7
			l-1.7-5.5l1.3-2.6l4.4,2.1l7.4,11.3l-10.9,6.8l2.8,2.3l-0.1,3.3l3.4,8.2l5.7-1.8l16.7-6.9l3.5,1.4l-11,13l11.7-7.6
			c1-0.6,2.3-1.2,2.8-1.3c0.4,0.4,1.5,1.9,2.2,2.8c3.3,2.9,3.4,3,3.6,3h0.3l0.1-0.3c0.1-0.3-0.1-0.5-0.3-0.8c0-0.1-0.2-1.3,5.1-8.6
			l3.8-1.2l7.7,0.1l16,3.1c0.3,4.2,0.6,9.4,0.6,9.4v0.2l4.7,4l1.6-6.3l3.4-0.5l2.9,8.6l3.4-12.5l1.9-2.8l0.8-0.9l6.9-1.2l13.6,5.8
			l10.1-1.8l8.6,2.8l3.7,5.1l-4.9,5.7l-13.3,8.7l-18.1,0.8l-5.4,3.1l-0.9-1.3l-3.5,1.5l0.7,1.9l-2.7,4.4l9.7,6.1l0.3,0.2l-0.2,2.9
			l-16.4,2.6l-1.5,1.6l-0.2,3.5l-4.7,11.5l-5.8,10l-1.8,8.9l-6.8-2.6l-4.5,4.2l-5.7,11.2l-1,6.5l-7.9,7.6l-1.3,5.1l1.8,1.2l-0.9,3.1
			l-3.8,2.7l-4.7-0.8l0.1-1.2l3.2-2.1l-0.2-6.1l3.3-10.5l-12.6-11.4l-1.7-3.4l-0.3-3l-1.8-0.2l-1.5,2.2l-4.8-3.2l-4.9-6.4l-1.5-3.3
			l-9.9-7.1l-6-6.2l-0.1,3l4.1,5.7l8.5,6.9l3.4,6.8l4.6,5.4l3.2,1.6l2.4,3l3.8,8.3l-14.5,17.8l4.7,5.5l-4.2,6.3l2.2,3.5l-6.9,13.9
			l-3.3,10.4l-1.2-0.1l0.1-3.3l-6.8-3.6l-7.6,0.9l-5.7,7.3l-5.8,2.7l-3.1,9l-10.5,4.6l-11,6L628.2,485.9z"/>
                    <circle class={"circle"} cx="686.2" cy="489.9" r="8.4"/>
                    <circle class={"circle"} cx="699.2" cy="385.3" r="8.4"/>
                    <g>
                        <path
                            d="M704,463.6v12.3h-2.2v-9l0,0l-3.2,9h-2l-3.2-8.9l0,0v8.9h-2.2v-12.3h3.2l3.3,9.5l0,0l3.3-9.5H704z"/>
                        <path d="M711.8,463.5c3.9,0,5.9,2.8,5.9,6.3s-2,6.3-5.9,6.3s-5.9-2.8-5.9-6.3S707.9,463.5,711.8,463.5z M711.8,474.3
				c2.4,0,3.6-2.1,3.6-4.5s-1.1-4.5-3.6-4.5c-2.4,0-3.6,2.1-3.6,4.5C708.2,472.2,709.3,474.3,711.8,474.3z"/>
                        <path d="M729.9,463.6v12.3h-2.1l-6.1-8.9l0,0v8.9h-2.2v-12.3h2.4l5.7,8.6l0,0v-8.6H729.9z"/>
                        <path d="M731.8,469.8c0-3.6,2.1-6.3,5.8-6.3c2.2,0,3.8,1,4.8,2.6l-1.7,1.1c-0.6-1.2-1.6-1.9-3-1.9c-2.4,0-3.5,2.1-3.5,4.5
				s1.1,4.5,3.6,4.5c1.4,0,2.4-0.7,3-1.9l1.7,1c-1,1.6-2.6,2.7-4.8,2.7C733.9,476.1,731.8,473.4,731.8,469.8z"/>
                        <path d="M746.9,475.9v-10.4h-4.1v-1.9h10.4v1.9h-4.1v10.4H746.9z"/>
                        <path d="M759.8,463.5c3.9,0,5.9,2.8,5.9,6.3s-2,6.3-5.9,6.3s-5.9-2.8-5.9-6.3S755.9,463.5,759.8,463.5z M759.8,474.3
				c2.4,0,3.6-2.1,3.6-4.5s-1.1-4.5-3.6-4.5c-2.4,0-3.6,2.1-3.6,4.5C756.2,472.2,757.3,474.3,759.8,474.3z"/>
                        <path d="M778,463.6v12.3h-2.1l-6.1-8.9l0,0v8.9h-2.2v-12.3h2.4l5.7,8.6l0,0v-8.6H778z"/>
                    </g>
                    <g>
                        <path d="M709,363.4c1.2,0.4,2.3,1.2,2.3,2.8c0,2.2-1.9,3.4-4.3,3.4h-5.3v-12.3h5.4c2.5,0,3.9,1.3,3.9,3.2
				C711,362.1,710.2,362.9,709,363.4L709,363.4z M703.9,359.3v3.4h3.1c0.9,0,1.7-0.5,1.7-1.7s-0.8-1.7-1.7-1.7L703.9,359.3
				L703.9,359.3z M707.2,367.9c0.9,0,1.7-0.5,1.7-1.7s-0.8-1.7-1.7-1.7h-3.3v3.5L707.2,367.9L707.2,367.9z"/>
                        <path d="M718.4,357.3c3.9,0,5.9,2.8,5.9,6.3s-2,6.3-5.9,6.3s-5.9-2.8-5.9-6.3S714.5,357.3,718.4,357.3z M718.4,368.1
				c2.4,0,3.6-2.1,3.6-4.5s-1.1-4.5-3.6-4.5c-2.4,0-3.6,2.1-3.6,4.5S715.9,368.1,718.4,368.1z"/>
                        <path d="M734.2,357.4h2.2v8c0,2.8-1.9,4.5-5.1,4.5c-2.9,0-5.1-1.6-5.1-4.5v-8h2.3v7.9c0,1.6,1.1,2.7,2.8,2.7c1.8,0,2.9-1,2.9-2.7
				L734.2,357.4L734.2,357.4z"/>
                        <path d="M738.2,363.6c0-3.6,2.1-6.3,5.8-6.3c2.2,0,3.8,1,4.8,2.6l-1.7,1.1c-0.6-1.2-1.6-1.9-3-1.9c-2.4,0-3.5,2.1-3.5,4.5
				s1.1,4.5,3.6,4.5c1.4,0,2.4-0.7,3-1.9l1.7,1c-1,1.6-2.6,2.7-4.8,2.7C740.2,369.9,738.2,367.2,738.2,363.6z"/>
                        <path d="M753.2,369.7v-10.4h-4.1v-1.9h10.4v1.9h-4.1v10.4H753.2z"/>
                        <path d="M766.2,357.3c3.9,0,5.9,2.8,5.9,6.3s-2,6.3-5.9,6.3s-5.9-2.8-5.9-6.3S762.2,357.3,766.2,357.3z M766.2,368.1
				c2.4,0,3.6-2.1,3.6-4.5s-1.1-4.5-3.6-4.5c-2.4,0-3.6,2.1-3.6,4.5C762.5,366,763.7,368.1,766.2,368.1z"/>
                        <path d="M781.8,357.4h2.2v8c0,2.8-1.9,4.5-5.1,4.5c-2.9,0-5.1-1.6-5.1-4.5v-8h2.3v7.9c0,1.6,1.1,2.7,2.8,2.7c1.8,0,2.9-1,2.9-2.7
				L781.8,357.4L781.8,357.4z"/>
                        <path d="M785.8,363.6c0-3.6,2.1-6.3,5.8-6.3c2.2,0,3.8,1,4.8,2.6l-1.7,1.1c-0.6-1.2-1.6-1.9-3-1.9c-2.4,0-3.5,2.1-3.5,4.5
				s1.1,4.5,3.6,4.5c1.4,0,2.4-0.7,3-1.9l1.7,1c-1,1.6-2.6,2.7-4.8,2.7C787.9,369.9,785.8,367.2,785.8,363.6z"/>
                        <path d="M797.8,369.7v-12.3h2.3v5.2h5.6v-5.2h2.3v12.3h-2.3v-5.3h-5.6v5.3H797.8z"/>
                        <path d="M810.2,369.7v-12.3h8.2v1.8h-6v3.3h5.2v1.8h-5.2v3.4h6.1v1.8h-8.3V369.7z"/>
                    </g>
                </g>
                <g onClick={zoneChange2} className={"z2 zone"}>
                    <polygon class="st0"
                             points="252.6,583 247,577.8 205.3,315.5 189.4,308.8 175.8,290.1 158.8,278.5 144.3,271.2 139.4,264 121.8,262.5 107.8,260 100.2,264.9 102.6,278.3 93.8,280.5 87.9,277.9 70.2,283.4 67.2,293.5 49.3,297.4 24.5,315.2 1.2,300.7 0.4,291.1 35.3,275.1 68.9,252.7 94.4,219.7 86.8,167.4 313.3,233.2 375,327.4 299.5,565.4 299.5,583.4 292.1,586.1 287.8,573 281.4,576.2 268.8,574.5 272.8,586.1 262.1,584.2 262.1,587.5 		"/>
                    <circle class={"circle"} cx="212.5" cy="307.2" r="8.4"/>
                    <circle class={"circle"} cx="107.3" cy="249.9" r="8.4"/>
                    <g>
                        <path d="M111.1,234.8v-12.3h8.2v1.8h-6v3.3h5.2v1.8h-5.2v3.4h6.1v1.8h-8.3V234.8z"/>
                        <path d="M121.2,222.5h4.4c4.3,0,6.2,2.8,6.2,6.1c0,3.6-2.2,6.2-6.1,6.2h-4.5L121.2,222.5L121.2,222.5z M125.4,233
				c2.5,0,4-1.4,4-4.3c0-3-1.7-4.3-4.1-4.3h-1.9v8.6L125.4,233L125.4,233z"/>
                        <path
                            d="M146.4,222.5v12.3h-2.2v-9l0,0l-3.2,9h-2l-3.2-8.9l0,0v8.9h-2.2v-12.3h3.2l3.3,9.5l0,0l3.3-9.5H146.4z"/>
                        <path d="M156.8,222.5h2.2v8c0,2.8-1.9,4.5-5.1,4.5c-2.9,0-5.1-1.6-5.1-4.5v-8h2.3v7.9c0,1.6,1.1,2.7,2.8,2.7c1.8,0,2.9-1,2.9-2.7
				L156.8,222.5L156.8,222.5z"/>
                        <path d="M171.6,222.5v12.3h-2.1l-6.1-8.9l0,0v8.9h-2.2v-12.3h2.4l5.7,8.6l0,0v-8.6H171.6z"/>
                        <path d="M173.9,222.5h4.4c4.3,0,6.2,2.8,6.2,6.1c0,3.6-2.2,6.2-6.1,6.2H174L173.9,222.5L173.9,222.5z M178.2,233
				c2.5,0,4-1.4,4-4.3c0-3-1.7-4.3-4.1-4.3h-1.9v8.6L178.2,233L178.2,233z"/>
                        <path d="M185.5,233.2l1.4-1.5c0.9,0.9,2.1,1.5,3.5,1.5c1.7,0,2.5-0.7,2.5-1.7c0-1.1-1.1-1.4-2.9-1.9c-2-0.5-4-1.2-4-3.6
				c0-2.1,1.6-3.7,4.5-3.7c1.9,0,3.4,0.6,4.5,1.7l-1.3,1.5c-0.8-0.9-1.9-1.4-3.2-1.4c-1.5,0-2.2,0.7-2.2,1.6c0,1.1,1,1.4,2.8,1.8
				c2.2,0.5,4.1,1.2,4.1,3.6c0,2.2-1.7,3.7-4.8,3.7C188.3,235,186.8,234.4,185.5,233.2z"/>
                        <path d="M199.8,234.8v-10.4h-4.1v-1.9h10.4v1.9H202v10.4H199.8z"/>
                        <path d="M212.5,222.3c3.9,0,5.9,2.8,5.9,6.3s-2,6.3-5.9,6.3s-5.9-2.8-5.9-6.3C206.6,225.2,208.8,222.3,212.5,222.3z M212.5,233.2
				c2.4,0,3.6-2.1,3.6-4.5s-1.1-4.5-3.6-4.5c-2.4,0-3.6,2.1-3.6,4.5C209,231.1,210.1,233.2,212.5,233.2z"/>
                        <path d="M230.8,222.5v12.3h-2.1l-6.1-8.9l0,0v8.9h-2.2v-12.3h2.4l5.7,8.6l0,0v-8.6H230.8z"/>
                    </g>

                    <g>
                        <path d="M226,286.4v6.6h-2v-1.5l0,0c-0.6,0.9-1.8,1.6-3.5,1.6c-3.5,0-5.5-2.8-5.5-6.2c0-3.6,2.1-6.4,6-6.4c2.2,0,3.9,0.9,4.9,2.5
				l-1.7,1.2c-0.7-1.2-1.7-1.8-3.2-1.8c-2.4,0-3.7,2-3.7,4.5c0,2.6,1.2,4.5,3.6,4.5c1.8,0,3-1.1,3-2.9v-0.3h-2.8v-1.8L226,286.4
				L226,286.4z"/>
                        <path d="M233.1,288h-2.6v5h-2.3v-12.3h5.3c2.4,0,4.1,1.3,4.1,3.5c0,1.7-1,2.8-2.4,3.3l2.7,5.5h-2.5L233.1,288z M230.4,286.2h3
				c1,0,1.8-0.6,1.8-1.8c0-1.3-0.8-1.8-1.8-1.8h-3V286.2z"/>
                        <path
                            d="M246.9,290h-5.1l-1.1,3h-2.3l4.6-12.3h2.7l4.6,12.3h-2.4L246.9,290z M246.2,288.2l-1.9-5.3l0,0l-1.9,5.3H246.2z"/>
                        <path d="M262,280.7V293H260l-6.1-8.9l0,0v8.9h-2.2v-12.3h2.4l5.7,8.6l0,0v-8.6H262z"/>
                        <path d="M264.3,280.7h4.4c4.3,0,6.2,2.8,6.2,6.1c0,3.6-2.2,6.2-6.1,6.2h-4.5L264.3,280.7L264.3,280.7z M268.6,291.2
				c2.5,0,4-1.4,4-4.3c0-3-1.7-4.3-4.1-4.3h-1.9v8.6L268.6,291.2L268.6,291.2z"/>
                        <path d="M281,293v-12.3h8.2v1.8h-5.9v3.5h5.2v1.8h-5.2v5.1H281V293z"/>
                        <path
                            d="M297.1,290H292l-1.1,3h-2.3l4.6-12.3h2.7l4.6,12.3h-2.4L297.1,290z M296.5,288.2l-1.9-5.3l0,0l-1.9,5.3H296.5z"/>
                        <path d="M301.8,280.7h2.3v10.4h5.9v1.9h-8.2V280.7z"/>
                        <path d="M311.5,280.7h2.3v10.4h5.9v1.9h-8.2V280.7z"/>
                        <path d="M320.4,291.3l1.4-1.5c0.9,0.9,2.1,1.5,3.5,1.5c1.7,0,2.5-0.7,2.5-1.7c0-1.1-1.1-1.4-2.9-1.9c-2-0.5-4-1.2-4-3.6
				c0-2.1,1.6-3.7,4.5-3.7c1.9,0,3.4,0.6,4.5,1.7l-1.3,1.5c-0.8-0.9-1.9-1.4-3.2-1.4c-1.5,0-2.2,0.7-2.2,1.6c0,1.1,1,1.4,2.8,1.8
				c2.2,0.5,4.1,1.2,4.1,3.6c0,2.2-1.7,3.7-4.8,3.7C323.2,293.2,321.6,292.5,320.4,291.3z"/>
                    </g>

                </g>
                <g onClick={zoneChange3} className={"z3 zone"}>
                    <path class="st0" d="M410.8,767.6l-4.3-8.4c0.7-0.4,2.1-1,3.3-1.2l0.4-0.1l0.2-1.3l-1.4-1.3l0.3-5.1l0.5-0.5l3.3,2.7l3.3-2.8l-1.9-2.7l-2,1.6
			l-1.1-1.2l0.8-2l-5.7-1.5l-4.5-3.2l-1.2-1.3l-1.1-3.7l-2.4-0.7l0.7,6.9l-2.2-0.9v2.5l-2.4,0.6l-3.5-4l-3.5-0.4l1.4,4.1l0.8,5.4
			l-0.6-0.8l-3.4,0.9l0.7,1l-2.2-0.3l1.2,3.4l0.3,9.8l4.4,4.4l0.7,2.2l-0.7,0.1l-3.2-1.6L383,769l-9.3-15.2l-1.8-0.9l1.1-3.8l-2-4.4
			l-0.6-3.3l-2.8-2.4l-3.2-6.8l-4.2-3.3l-1.4,4.8l2.8,2l0.3,2.6l-3.4,1.6l7.1,1.9l0.3,1.1l-2.7,0.8l-4.7-0.9l-0.1,1.2l-2,1.5
			l-16.6-14.6l-0.8,10.6l-7.1,6.8l-11.3-8.8l-2.6-6.6l6.1-30.7l-16.2-15.1l174.2-28.7l9.9,40.3l135.7-211.6l61.2,117.4l-4.1,0.9
			l-32.3,23.5l-0.6,2.7L636.7,644l-0.7,4.5l-10.9,7.6l-1.2,5.5l1.8,2.1l-5.6,5l-2.4-0.5l-12.5,9.8l-7.9,2.1l-2.1,5.7l-9.3-1.3
			l-11.2,17l-5.6,2l-7.2-5.5l-3.8,3.9l-3.9-1.6l-5.1-12.3l-6.3,4.7v2.8l-7.1,5l1,4.4l-3.2,3.5l-5.7-0.4l4,5.3l-9.7,11l-2.6-0.1
			l1.3-5.9h-11.3L504,721c1.1-4,1.1-4.2,1.1-4.3c0-0.2,0.3-3.5,0.6-5.9l3.6-2.2l-0.7-0.4c-2.6-1.5-6.8-3.9-7.4-4.3
			c-0.3-0.7-1.8-5.1-2.8-7.9l-1.3-3.7l1,13.4l4.3,3l-0.4,3l-1.3,1.7v0.1c-0.3,2.7-0.6,6.5-0.8,8.2c-0.1-0.1-0.3-0.3-0.5-0.4
			c-0.7-0.6-2.2-1.8-2.4-2.2v-4l-8.9-10.8l1.7,10.4l2,0.9l0.1,3.7l4.7,6.1c0.1,3.3,0.5,3.3,0.9,3.3h0.2l0.2-0.2
			c0.4-0.3,4-1.9,6.2-2.8c2.4-1.1,2.9-1.3,3.1-1.4c0.4-0.2,3.9-1.3,6.3-2.1l2.3,2.7l-14.2,7.1l-10.3,8.3l0.7,3.4l-5.5,2.8l-0.7,3.1
			l-1.4-1.1l-3.9-13.5l-3.4-0.1l2.6-4.5l-2-1.3l-5.5,5.6l-8.8,3.4l0.7,4.4l-11.3,5.8l0.7,2.2l-9.7,6.2l-1.1-6.1l-4.6,4.2l2.1,3.4
			l-5.1,2.6l-1.6-1.6l-4.4,3.9l-1.7-1.6l2.6-4.3l1.9-0.7l1.5-2.3l-4.6-4.4l-3.1,4.2l2.2,2.2l-1.6,2.3l-2.7-4l-1.9,2.5l1.3,4
			l-4.4,2.8l-0.1,0.2c-0.2,0.6-1.7,5.8-1.7,6.8c0,0.2-0.9,0.6-2,0.8L410.8,767.6z"/>
                    <polygon class="st0" points="399.8,794.6 397.8,791.3 396.8,787.9 395.5,788.6 395.4,788.5 399.9,780 406.3,774.7 407.4,777.1 406.2,786.8
			404,793.6 401.8,796.6 401.9,797.5 		"/>
                    <path class="st0" d="M414.9,817.8l-2-0.3l-1.5,1.1l-0.5-0.6l0.3-1l-2.4-1.1l-0.1,1.2l-0.2-0.1l0.2-1.2l-0.9-1.7l-1.2-0.1l-0.2-1.4l0.5-1.5h0.6
			l1.8-1l0.8-1.9l0.1,0.7l2.3-3.8l1-0.3l-0.4-1.9l1.5-2.6l-1.4-1.2l-0.4-1.6l-1.6,0.9l-1.2-0.9l2.7-5l1-0.8l-0.2,0.3l1,1.9l0.8-0.1
			l-0.7,4l2.1-1.4l2,3.4l-0.1,1l-1.7,2.4l0.6,0.7l-0.3,2.2l-1.2,2.8l-2.2,0.7l-0.7,2.4l0.7,1.9l2.5,2.8l-1,2.8L414.9,817.8z
			 M408.8,806.5l-0.4-2.6l0.7-2.3l1.7-0.8l0.6,0.8l-0.5,0.5v2.1l-1.2,1l0.3,2L408.8,806.5z"/>
                    <polygon class="st0" points="427,869.5 429.3,866.3 429.4,861.3 428.5,859.3 430.9,850.3 430.5,847.5 432,840.7 433.2,838.6 434,837.9
			434,836.1 434.8,831.5 440.4,824.1 442,823.9 442.7,824.4 445.5,830.1 447.8,827.7 449.4,830.5 448.9,831.2 446.8,830.4
			445.2,833.9 448.9,840.8 450.7,841.7 451.4,843.4 450.8,846.4 452.5,848.1 453.2,851.7 451.2,852 449.5,848.3 446.3,850.8
			449.4,854.5 449.2,856.3 448,857 447.2,856 442.7,856.3 440.2,859.5 436.8,859.1 435,864.7 432.8,868.1 431.8,868.5 430.8,872.8
			427.8,873.6 		"/>
                    <path class="st0"
                          d="M438,872.5c0.1-0.7,0.1-1.4,0.2-1.6c0.1-0.2,0.4-0.8,1.4-3.1l1.4-1l1.5-0.5l0.6,1.9l-3,1.7l0.6,1.7l-2,0.8L438,872.5z"/>
                    <polygon class="st0"
                             points="456.8,864.4 456.8,860.8 455.3,858.9 454,856.8 454.9,856.4 456.2,859 459.3,862.1 461.3,863.9 458.7,864.7 		"/>
                    <circle class={"circle"} cx="548.2" cy="690.2" r="8.4"/>
                    <circle class={"circle"} cx="340" cy="731.7" r="8.4"/>
                    <g>
                        <path d="M344.2,714.6l1.4-1.5c0.9,0.9,2.1,1.5,3.5,1.5c1.7,0,2.5-0.7,2.5-1.7c0-1.1-1.1-1.4-2.9-1.9c-2-0.5-4-1.2-4-3.6
				c0-2.1,1.6-3.7,4.5-3.7c1.9,0,3.4,0.6,4.5,1.7l-1.3,1.5c-0.8-0.9-1.9-1.4-3.2-1.4c-1.5,0-2.2,0.7-2.2,1.6c0,1.1,1,1.4,2.8,1.8
				c2.2,0.5,4.1,1.2,4.1,3.6c0,2.2-1.7,3.7-4.8,3.7C347,716.4,345.4,715.8,344.2,714.6z"/>
                        <path d="M358.4,716.3v-10.4h-4.1V704h10.4v1.9h-4.1v10.4H358.4z"/>
                        <path d="M364.8,714h2.3v2.3h-2.3V714z"/>
                        <path d="M373,714.6l1.4-1.5c0.9,0.9,2.1,1.5,3.5,1.5c1.7,0,2.5-0.7,2.5-1.7c0-1.1-1.1-1.4-2.9-1.9c-2-0.5-4-1.2-4-3.6
				c0-2.1,1.6-3.7,4.5-3.7c1.9,0,3.4,0.6,4.5,1.7l-1.3,1.5c-0.8-0.9-1.9-1.4-3.2-1.4c-1.5,0-2.2,0.7-2.2,1.6c0,1.1,1,1.4,2.8,1.8
				c2.2,0.5,4.1,1.2,4.1,3.6c0,2.2-1.7,3.7-4.8,3.7C375.8,716.4,374.2,715.8,373,714.6z"/>
                        <path d="M387.2,716.3v-10.4h-4.1V704h10.4v1.9h-4.1v10.4H387.2z"/>
                        <path d="M394.9,716.3V704h8.2v1.8h-6v3.3h5.2v1.8h-5.2v3.4h6.1v1.8h-8.3V716.3z"/>
                        <path d="M410.2,704c2.4,0,4.5,1.4,4.5,4s-2,4.1-4.5,4.1h-2.9v4.2H405V704H410.2z M410.2,710.2c1.2,0,2.2-0.7,2.2-2.2
				s-1-2.2-2.2-2.2h-2.9v4.4H410.2z"/>
                        <path d="M416.3,716.3V704h2.3v5.2h5.6V704h2.3v12.3h-2.3V711h-5.6v5.3H416.3z"/>
                        <path d="M428.8,716.3V704h8.2v1.8h-6v3.3h5.2v1.8h-5.2v3.4h6.1v1.8h-8.3V716.3z"/>
                        <path d="M449.2,704v12.3h-2.1l-6.1-8.9l0,0v8.9h-2.2V704h2.4l5.7,8.6l0,0V704H449.2z"/>
                    </g>
                    <g>
                        <path d="M550.9,674.5l1.4-1.5c0.9,0.9,2.1,1.5,3.5,1.5c1.7,0,2.5-0.7,2.5-1.7c0-1.1-1.1-1.4-2.9-1.9c-2-0.5-4-1.2-4-3.6
				c0-2.1,1.6-3.7,4.5-3.7c1.9,0,3.4,0.6,4.5,1.7l-1.3,1.5c-0.8-0.9-1.9-1.4-3.2-1.4c-1.5,0-2.2,0.7-2.2,1.6c0,1.1,1,1.4,2.8,1.8
				c2.2,0.5,4.1,1.2,4.1,3.6c0,2.2-1.7,3.7-4.8,3.7C553.8,676.3,552.2,675.7,550.9,674.5z"/>
                        <path
                            d="M569.5,673.2h-5.1l-1.1,3H561l4.6-12.3h2.7l4.6,12.3h-2.4L569.5,673.2z M568.8,671.3l-1.9-5.3l0,0l-1.9,5.3H568.8z"/>
                        <path d="M574.2,676.2v-12.3h2.3v12.3H574.2z"/>
                        <path d="M589.2,663.9v12.3h-2.1l-6.1-8.9l0,0v8.9h-2.2v-12.3h2.4l5.7,8.6l0,0v-8.6H589.2z"/>
                        <path d="M594.7,676.2v-10.4h-4.1v-1.9H601v1.9h-4.1v10.4H594.7z"/>
                        <path d="M604.8,675.6l0.9-1.6c0.4,0.3,0.9,0.6,1.5,0.6c1.1,0,1.6-0.7,1.6-1.7v-9h2.3v9c0,2-1.3,3.5-3.7,3.5
				C606.3,676.3,605.4,676,604.8,675.6z"/>
                        <path d="M618.8,663.7c3.9,0,5.9,2.8,5.9,6.3s-2,6.3-5.9,6.3s-5.9-2.8-5.9-6.3C612.9,666.5,615,663.7,618.8,663.7z M618.8,674.5
				c2.4,0,3.6-2.1,3.6-4.5s-1.1-4.5-3.6-4.5c-2.4,0-3.6,2.1-3.6,4.5C615.2,672.4,616.4,674.5,618.8,674.5z"/>
                        <path d="M626.7,676.2v-12.3h2.3v5.2h5.6v-5.2h2.3v12.3h-2.3v-5.3H629v5.3H626.7z"/>
                        <path d="M649.5,663.9v12.3h-2.1l-6.1-8.9l0,0v8.9h-2.2v-12.3h2.4l5.7,8.6l0,0v-8.6H649.5z"/>
                    </g>
                </g>
                <g onClick={zoneChange4} className={"z4 zone"}>
                    <path class="st0" d="M443.4,430.1L314,232.3l-227.4-66l-3.9-26.8l38.3-3.3l0.5-22.4l68.3-8.9l-1.3,2.6l4.8,9.8l7.7,4.9l15.3,0.9l14.6,9.9
			l13-0.5l2-12.3l8.5,1.1l12,1.8l10.3-9.3l8.8-6.8l18.8,5.8l2.9-7.3l-0.7-14.9l9.9-3.9l11,0.6l1.6-2.1l1.5-0.9l1.3,4.3l12.5-15.4
			l4.4,0.9l15.6-8.8l10.2-9l14.4-4.5l3.7,5.8l-2,6.5l41,10.3l5.4,4.9l28.7-2.8l11.2,1.2l12,7.8l5.8,7.1l5.3,9.5l4.9,19.4l12.4,7.9
			l-3,7.6l-3.7,0.2l4.4,5.5l4,0.2l3.5-12.6l4.3-3.4l5.6-0.2l5.6-2.4l10-11l7.8-11.7l7-3.1l9.4-10.5l8.3-6.1l1.6,0.7l3.6-3l24.5-9.7
			l3.3,0.4l-0.3,1.5l-3,2.4l-9.7,8.9l2.1,4.5l5.1-2.2l5-0.3l5.1-5.3l16.6-7.2h6.8l4.4,6.6l-4.3,6.1l-8.3,2.2l0.2,1l16.2-2.2l2.6,5.6
			l5,1.8l0.9-3.9l-4.7-4.7l0.3-3.6l2.9-3.5l12,9.6l-11.2,12.2l-2.8,2.9l-1.2-0.3c-2.4-0.7-5.8-1.7-6.2-1.9c-0.4-0.4-4.3-3.7-4.5-3.9
			l-0.7-0.6l-0.1,0.9c-0.1,0.6-0.2,1.2-0.3,1.6c-0.8-0.7-2.2-1.8-2.4-2l-0.3-0.2l-3.9,2.6l-0.9,3.5l-22.1,3.9l17.4,1.5l1.8,2.2h3.3
			l0.8-3.2l3.4-0.1c0.7,7.9,1.3,7.9,1.8,7.9l0,0c0.5,0,0.7-0.6,0.7-0.8c0.6-2,1.8-6.8,2.2-8.6l3.1,2.4l7.3-0.4
			c-1.9,6.9-4.7,16.4-5.7,19.4c-0.5-1.3-1.5-3.7-2.2-5.5l-0.3-0.7l-2.4,2.4l-1,1.9l-3.7,1l-1.7,2.6l1.5,3.5l4.9,2.4l2,2.2l2.5,0.3
			l-3.3,0.7c-0.6-1.1-1-1.7-1.2-1.9c-0.1-0.1-0.2-0.2-3.5-1.7l-0.2-0.1l-3.7,2.4c-0.2,0-0.3,0-0.3,0c-0.2,0-0.9-0.2-1-0.3
			s-1.8-1.5-2.3-1.9l-0.2-0.2l-0.2,0.1c-1,0.3-5.8,1.7-6.5,3.2c-0.1,0.3-0.2,0.6,0,0.9c0.7,1.6,2,2.6,2,2.6l0.2,0.2l0.3-0.1
			c2.5-1.2,3.3-1.6,3.6-1.8c0.2,0,0.7,0,2.3,0c0.9,0,2,0,2.8,0l1.1,2.7l-1.6,0.7l1.1,1.7h0.3c0,0,3.1-0.3,5.1-0.3c0.1,0,0.3,0,0.4,0
			c-0.8,0.3-2.1,0.7-4.3,1.4l-0.7,0.2l1.3,2.1l-12.5,14l7.1-2.1c-2.1,7.4-1.7,7.9-1.6,8.1l0.2,0.2h0.3c0.4,0,0.7-0.3,1-1
			c1.5-2.6,7-9.1,9.8-12.3l2.3,4.5c2.6,16.7,2.8,16.9,3,17.1c0.1,0.1,0.3,0.2,0.5,0.2c0.1,0,0.3,0,0.5-0.1c-0.3,1.4-0.8,3.8-0.8,4.7
			c0,0.3-0.7,0.4-1.3,0.4c-0.3,0-0.6,0-0.8,0l-1.6-12.3h-4.1l-3.8,1.7l-1.6,10.2c-1.7,0.4-3.7,0.9-4.2,1.1l-9.1-1.6l-11.4,8.3
			l-9.7-1.7l8.9,5.8l13.7-7.3l10.4,5.5l1.3,1.8l3.3-4h1.4l-1.4,12.3c-0.2,0.6-2.5,6.6-4.3,10.2c0.1-0.4,0.2-0.8,0.3-1.1v-0.1l0.9-11
			l-7.9,1.3l-11.5,14l1.1,2.3l-22.3,28.2l-4.3,1.7l-13.3,9.7c-2.3,1.6-14.1,9.6-14.7,10.1c-0.4,0.3-4,1.4-7.3,2.3l-0.3,0.1l-1.6,14
			l-6.5,4.5l-13.5,1.8l-0.1,0.1c-0.1,0.1-14.2,12.2-19.7,16.6c-2.4,1.7-2.4,1.7-2.4,2v0.5h0.5c0.2,0,0.3,0,2.5-1.7
			c5.4-3.8,19.1-13,20.4-13.9l12.7-1.2l3.2,15.9l2.6,0.5v-17.1l5.8-4l1.6-13.1c2.9-1,9-3.3,9.6-3.9s10.1-10.5,11.2-11.6l5.6-1.8
			l2.8,0.4l3,2l2.8-1.5l1-3.2l2.9,1.5l7.4-4l0.8,4l-2,6l10.5-2.2l7.7-9.1l4.1-0.1l6.4-4.6l2.8-0.1l3.5,5.6l-2,4.8l10.5-7.5l-0.9-3.2
			l9.1-3l12.3-1.2l0.6,0.9l-2.6,1.1l-1,3.5l-97,118.1l-30.5,78.1L443.4,430.1z"/>
                    <polygon class="st0" points="671.2,40.1 670.2,37.2 671,33.3 674.8,26.6 675.8,23.1 675.7,20.6 674.8,17.4 674.5,15.8 675.2,13.9 675.9,13.3
			677.3,12.9 682.3,14.6 684.4,18 688.7,28.7 686.7,32.8 685.9,32.5 686.3,30.3 683.8,30.9 681,33.8 680.8,35.6 679.3,36.6
			677.8,36.6 677,37.3 675.7,40.5 673.8,41.9 672.5,42 		"/>
                    <polygon class="st0" points="669.5,80.6 670.3,80.2 668.7,78.1 664.7,79.1 662.5,77.5 662.5,76.5 665.9,73.9 668.2,71.5 670.9,70 670.2,68.6
			667.5,68.5 666.5,66.2 665,66.2 663.8,65.5 663,68.3 663.8,68.7 661.8,71 661.2,70.5 661.9,67.5 664.4,63.9 661.9,62.2
			660.5,64.5 658.9,65.2 658.9,62.6 658,61.9 657.9,60.5 659.2,57.7 659.4,54.9 661.2,54.7 662,51.6 668.8,48.5 664.4,48.8
			664.5,48.4 667.2,46.7 676.2,46.9 676.3,49.6 683,49.4 686.2,44.5 686.8,50.7 683.8,60.9 679.8,71.2 676.8,74.5 674.8,78.7
			671.2,82.8 		"/>
                    <polygon class="st0" points="648.5,71.2 645.9,71.5 643.8,68.6 644.5,65.4 643.3,64.8 643.8,64 647.4,63.6 649.8,65.1 647.7,66.7 649.5,68.7
			649.5,71 651.3,71.9 650.9,72.5 		"/>
                    <circle class={"circle"} cx="556.4" cy="258.3" r="8.4"/>
                    <circle class={"circle"} cx="504.2" cy="136.9" r="8.4"/>
                    <circle class={"circle"} cx="323.8" cy="95.5" r="8.4"/>
                    <circle class={"circle"} cx="657.4" cy="83.5" r="8.4"/>
                    <g>
                        <path d="M660.8,70.8l1.4-1.5c0.9,0.9,2.1,1.5,3.5,1.5c1.7,0,2.5-0.7,2.5-1.7c0-1.1-1.1-1.4-2.9-1.9c-2-0.5-4-1.2-4-3.6
				c0-2.1,1.6-3.7,4.5-3.7c1.9,0,3.4,0.6,4.5,1.7l-1.3,1.5c-0.8-0.9-1.9-1.4-3.2-1.4c-1.5,0-2.2,0.7-2.2,1.6c0,1.1,1,1.4,2.8,1.8
				c2.2,0.5,4.1,1.2,4.1,3.6c0,2.2-1.7,3.7-4.8,3.7C663.5,72.6,661.9,72,660.8,70.8z"/>
                        <path d="M672,72.4V60.1h2.3v5.2h5.6v-5.2h2.3v12.3h-2.3v-5.3h-5.6v5.3H672z"/>
                        <path d="M684.4,72.4V60.1h2.3v12.3H684.4z"/>
                        <path d="M694.3,60.1c2.4,0,4.5,1.4,4.5,4c0,2.6-2,4.1-4.5,4.1h-2.9v4.2h-2.3V60.1H694.3z M694.2,66.4c1.2,0,2.2-0.7,2.2-2.2
				c0-1.5-1-2.2-2.2-2.2h-2.9v4.4H694.2z"/>
                        <path d="M705.7,60.1c2.4,0,4.5,1.4,4.5,4c0,2.6-2,4.1-4.5,4.1h-2.9v4.2h-2.3V60.1H705.7z M705.5,66.4c1.2,0,2.2-0.7,2.2-2.2
				c0-1.5-1-2.2-2.2-2.2h-2.9v4.4H705.5z"/>
                        <path
                            d="M717.8,69.4h-5.1l-1.1,3h-2.3l4.6-12.3h2.7l4.6,12.3h-2.4L717.8,69.4z M717,67.6l-1.9-5.3l0,0l-1.9,5.3H717z"/>
                        <path d="M732.7,65.8v6.6h-2v-1.5l0,0c-0.6,0.9-1.8,1.6-3.5,1.6c-3.5,0-5.5-2.8-5.5-6.2c0-3.6,2.1-6.4,6-6.4
				c2.2,0,3.9,0.9,4.9,2.5l-1.7,1.2c-0.7-1.2-1.7-1.8-3.2-1.8c-2.4,0-3.7,2-3.7,4.5c0,2.6,1.2,4.5,3.6,4.5c1.8,0,3-1.1,3-2.9v-0.3
				h-2.8v-1.8L732.7,65.8L732.7,65.8z"/>
                        <path
                            d="M742.2,69.4h-5.1l-1.1,3h-2.3l4.6-12.3h2.7l4.6,12.3h-2.4L742.2,69.4z M741.5,67.6l-1.9-5.3l0,0l-1.9,5.3H741.5z"/>
                        <path d="M757.3,60.1v12.3h-2.1l-6.1-8.9l0,0v8.9H747V60.1h2.4l5.7,8.6l0,0v-8.6H757.3z"/>
                    </g>
                    <g>
                        <path d="M326.6,75.7c0-3.6,2.1-6.3,5.8-6.3c2.2,0,3.8,1,4.8,2.6l-1.7,1.1c-0.6-1.2-1.6-1.9-3-1.9c-2.4,0-3.5,2.1-3.5,4.5
				s1.1,4.5,3.6,4.5c1.4,0,2.4-0.7,3-1.9l1.7,1c-1,1.6-2.6,2.7-4.8,2.7C328.8,82,326.6,79.3,326.6,75.7z"/>
                        <path
                            d="M346,78.8h-5.2l-1.1,3h-2.3l4.6-12.3h2.7l4.6,12.3h-2.4L346,78.8z M345.3,77l-1.9-5.3l0,0l-1.9,5.3H345.3z"/>
                        <path
                            d="M363.5,69.6v12.3h-2.2v-9l0,0l-3.2,9h-2l-3.2-8.9l0,0v8.9h-2.2V69.6h3.2l3.3,9.5l0,0l3.3-9.5H363.5z"/>
                        <path d="M371,69.6c2.4,0,4.5,1.4,4.5,4s-2,4.1-4.5,4.1h-2.9v4.2h-2.3V69.6H371z M370.9,75.8c1.2,0,2.2-0.7,2.2-2.2
				s-1-2.2-2.2-2.2H368v4.4H370.9z"/>
                        <path d="M384.4,75.6c1.2,0.4,2.3,1.2,2.3,2.8c0,2.2-1.9,3.4-4.3,3.4h-5.3V69.5h5.4c2.5,0,3.9,1.3,3.9,3.2
				C386.4,74.2,385.5,75.1,384.4,75.6L384.4,75.6z M379.4,71.4v3.4h3.1c0.9,0,1.7-0.5,1.7-1.7s-0.8-1.7-1.7-1.7H379.4z M382.8,80
				c0.9,0,1.7-0.5,1.7-1.7s-0.8-1.7-1.7-1.7h-3.3v3.5h3.3V80z"/>
                        <path d="M388.4,81.9V69.6h8.2v1.8h-6v3.3h5.2v1.8h-5.2v3.4h6.1v1.8h-8.3V81.9z"/>
                        <path d="M398.5,69.6h2.3V80h5.9v1.9h-8.2V69.6z"/>
                        <path d="M408.2,69.6h2.3V80h5.9v1.9h-8.2L408.2,69.6L408.2,69.6z"/>
                        <path d="M419,81.9V71.5h-4.1v-1.9h10.4v1.9h-4.1v10.4H419z"/>
                        <path d="M431.9,69.4c3.9,0,5.9,2.8,5.9,6.3s-2,6.3-5.9,6.3s-5.9-2.8-5.9-6.3S428.2,69.4,431.9,69.4z M431.9,80.2
				c2.4,0,3.6-2.1,3.6-4.5s-1.1-4.5-3.6-4.5c-2.4,0-3.6,2.1-3.6,4.5S429.5,80.2,431.9,80.2z"/>
                        <path d="M450.2,69.6v12.3h-2.1L442,73l0,0v8.9h-2.2V69.6h2.4l5.7,8.6l0,0v-8.6H450.2z"/>
                    </g>
                    <g>
                        <path
                            d="M571.4,230v12.3h-2.2v-9l0,0l-3.2,9h-2l-3.2-8.9l0,0v8.9h-2.2V230h3.2l3.3,9.5l0,0l3.3-9.5H571.4z"/>
                        <path d="M573.8,242.3V230h2.3v12.3H573.8z"/>
                        <path d="M583.2,237.3h-2.6v5h-2.3V230h5.3c2.4,0,4.1,1.3,4.1,3.5c0,1.7-1,2.8-2.4,3.3l2.7,5.5h-2.5L583.2,237.3z M580.5,235.5h3
				c1,0,1.8-0.6,1.8-1.8s-0.8-1.8-1.8-1.8h-3V235.5z"/>
                        <path
                            d="M597,239.3h-5.1l-1.1,3h-2.3l4.6-12.3h2.7l4.6,12.3H598L597,239.3z M596.3,237.5l-1.9-5.3l0,0l-1.9,5.3H596.3z"/>
                        <path
                            d="M614.5,230v12.3h-2.2v-9l0,0l-3.2,9h-2l-3.2-8.9l0,0v8.9h-2.2V230h3.2l3.3,9.5l0,0l3.3-9.5H614.5z"/>
                        <path d="M616.8,242.3V230h2.3v12.3H616.8z"/>
                        <path d="M621,236.2c0-3.6,2.1-6.3,5.8-6.3c2.2,0,3.8,1,4.8,2.6l-1.7,1.1c-0.6-1.2-1.6-1.9-3-1.9c-2.4,0-3.5,2.1-3.5,4.5
				s1.1,4.5,3.6,4.5c1.4,0,2.4-0.7,3-1.9l1.7,1c-1,1.6-2.6,2.7-4.8,2.7C623.2,242.5,621,239.8,621,236.2z"/>
                        <path d="M633,242.3V230h2.3v5.2h5.6V230h2.3v12.3h-2.3V237h-5.6v5.3H633z"/>
                        <path d="M645.4,242.3V230h2.3v12.3H645.4z"/>
                    </g>
                    <g>
                        <path d="M513.8,115.8c1.2,0.4,2.3,1.2,2.3,2.8c0,2.2-1.9,3.4-4.3,3.4h-5.3v-12.3h5.4c2.5,0,3.9,1.3,3.9,3.2
				C515.8,114.5,514.9,115.3,513.8,115.8L513.8,115.8z M508.8,111.7v3.4h3.1c0.9,0,1.7-0.5,1.7-1.7s-0.8-1.7-1.7-1.7H508.8z
				 M512,120.3c0.9,0,1.7-0.5,1.7-1.7s-0.8-1.7-1.7-1.7h-3.3v3.5h3.3V120.3z"/>
                        <path
                            d="M525.2,119.1h-5.1l-1.1,3h-2.3l4.6-12.3h2.7l4.6,12.3h-2.4L525.2,119.1z M524.4,117.3l-1.9-5.3l0,0l-1.9,5.3H524.4z"/>
                        <path d="M531.4,122.1v-10.4h-4.1v-1.9h10.4v1.9h-4.1v10.4H531.4z"/>
                        <path d="M539.2,122.1v-12.3h2.3v5.2h5.6v-5.2h2.3v12.3h-2.3v-5.3h-5.6v5.3H539.2z"/>
                        <path d="M559.5,109.8h2.2v8c0,2.8-1.9,4.5-5.1,4.5c-2.9,0-5.1-1.6-5.1-4.5v-8h2.3v7.9c0,1.6,1.1,2.7,2.8,2.7c1.8,0,2.9-1,2.9-2.7
				L559.5,109.8L559.5,109.8z"/>
                        <path d="M568.9,117.2h-2.6v5H564v-12.3h5.3c2.4,0,4.1,1.3,4.1,3.5c0,1.7-1,2.8-2.4,3.3l2.7,5.5h-2.5L568.9,117.2z M566.2,115.3h3
				c1,0,1.8-0.6,1.8-1.8s-0.8-1.8-1.8-1.8h-3V115.3z"/>
                        <path d="M574.4,120.5l1.4-1.5c0.9,0.9,2.1,1.5,3.5,1.5c1.7,0,2.5-0.7,2.5-1.7c0-1.1-1.1-1.4-2.9-1.9c-2-0.5-4-1.2-4-3.6
				c0-2.1,1.6-3.7,4.5-3.7c1.9,0,3.4,0.6,4.5,1.7l-1.3,1.5c-0.8-0.9-1.9-1.4-3.2-1.4c-1.5,0-2.2,0.7-2.2,1.6c0,1.1,1,1.4,2.8,1.8
				c2.2,0.5,4.1,1.2,4.1,3.6c0,2.2-1.7,3.7-4.8,3.7C577.2,122.3,575.7,121.7,574.4,120.5z"/>
                        <path d="M588.7,122.1v-10.4h-4.1v-1.9H595v1.9h-4.1v10.4H588.7z"/>
                    </g>
                </g>
                <g onClick={zoneChange5} className={"z5 zone"}>
                    <polygon xmlns="http://www.w3.org/2000/svg" class="st0"
                             points="482.2,656.9 308.4,685.5 319.6,678 314.8,656.4 293.8,638.4 286.2,643.7 268.2,645 262.1,636.9      254.1,638.2 247.8,619.5 253.7,604.8 245,594.2 252.6,583.2 246.7,577.7 227.8,459 375.2,328 442.6,430.9 539.3,443.2      609.1,449.5 626.8,486 491.8,695.9    "/>
                    <circle class={"circle"} cx="407.9" cy="563.3" r="8.4"/>
                    <circle class={"circle"} cx="264.3" cy="498.2" r="8.4"/>
                    <g>
                        <path d="M220.7,472.9l-3.5,12.3h-2.5l-2.5-9.1l0,0l-2.5,9.1h-2.5l-3.5-12.3h2.4l2.5,9.5l0,0l2.5-9.5h2.5l2.5,9.4l0,0
				l2.5-9.4H220.7z"/>
                        <path d="M227.1,472.7c3.9,0,5.9,2.8,5.9,6.3s-2,6.3-5.9,6.3s-5.9-2.8-5.9-6.3C221.3,475.5,223.3,472.7,227.1,472.7z
				 M227.2,483.5c2.4,0,3.6-2.1,3.6-4.5s-1.1-4.5-3.6-4.5c-2.4,0-3.6,2.1-3.6,4.5C223.7,481.4,224.7,483.5,227.2,483.5z"/>
                        <path d="M240.3,472.7c3.9,0,5.9,2.8,5.9,6.3s-2,6.3-5.9,6.3s-5.9-2.8-5.9-6.3C234.5,475.5,236.5,472.7,240.3,472.7z
				 M240.4,483.5c2.4,0,3.6-2.1,3.6-4.5s-1.1-4.5-3.6-4.5c-2.4,0-3.6,2.1-3.6,4.5C236.8,481.4,237.9,483.5,240.4,483.5z"/>
                        <path d="M248.1,472.9h4.4c4.3,0,6.2,2.8,6.2,6.1c0,3.6-2.2,6.2-6.1,6.2h-4.5V472.9L248.1,472.9z M252.4,483.3
				c2.5,0,4-1.4,4-4.3c0-3-1.7-4.3-4.1-4.3h-1.9v8.6L252.4,483.3L252.4,483.3z"/>
                        <path d="M259.7,483.5l1.4-1.5c0.9,0.9,2.1,1.5,3.5,1.5c1.7,0,2.5-0.7,2.5-1.7c0-1.1-1.1-1.4-2.9-1.9
				c-2-0.5-4-1.2-4-3.6c0-2.1,1.6-3.7,4.5-3.7c1.9,0,3.4,0.6,4.5,1.7l-1.3,1.5c-0.8-0.9-1.9-1.4-3.2-1.4c-1.5,0-2.2,0.7-2.2,1.6
				c0,1.1,1,1.4,2.8,1.8c2.2,0.5,4.1,1.2,4.1,3.6c0,2.2-1.7,3.7-4.8,3.7C262.5,485.3,260.9,484.7,259.7,483.5z"/>
                        <path d="M273.8,485.2v-10.4h-4.1v-1.9h10.4v1.9H276v10.4H273.8z"/>
                        <path d="M286.7,472.7c3.9,0,5.9,2.8,5.9,6.3s-2,6.3-5.9,6.3s-5.9-2.8-5.9-6.3S282.9,472.7,286.7,472.7z M286.7,483.5
				c2.4,0,3.6-2.1,3.6-4.5s-1.1-4.5-3.6-4.5c-2.4,0-3.6,2.1-3.6,4.5C283.1,481.4,284.3,483.5,286.7,483.5z"/>
                        <path d="M294,479c0-3.6,2.1-6.3,5.8-6.3c2.2,0,3.8,1,4.8,2.6l-1.7,1.1c-0.6-1.2-1.6-1.9-3-1.9
				c-2.4,0-3.5,2.1-3.5,4.5s1.1,4.5,3.6,4.5c1.4,0,2.4-0.7,3-1.9l1.7,1c-1,1.6-2.6,2.7-4.8,2.7C296.1,485.3,294,482.6,294,479z"/>
                        <path
                            d="M310.2,479.9h-2v5.3h-2.3v-12.3h2.3v5.2h2l2.9-5.2h2.5L312,479l3.7,6.2h-2.6L310.2,479.9z"/>
                    </g>
                    <g>
                        <path d="M415.2,550.9v-12.3h8.2v1.8h-5.9v3.5h5.2v1.8h-5.2v5.1h-2.3V550.9z"/>
                        <path d="M430,545.9h-2.6v5h-2.3v-12.3h5.3c2.4,0,4.1,1.3,4.1,3.5c0,1.7-1,2.8-2.4,3.3l2.7,5.5h-2.5L430,545.9z M427.3,544.1h3
				c1,0,1.8-0.6,1.8-1.8s-0.8-1.8-1.8-1.8h-3V544.1z"/>
                        <path d="M436.4,550.9v-12.3h8.2v1.8h-6v3.3h5.2v1.8h-5.2v3.4h6.1v1.8h-8.3V550.9z"/>
                        <path d="M446.4,538.6h4.4c4.3,0,6.2,2.8,6.2,6.1c0,3.6-2.2,6.2-6.1,6.2h-4.5V538.6z M450.8,549c2.5,0,4-1.4,4-4.3
				c0-3-1.7-4.3-4.1-4.3h-1.9v8.6H450.8z"/>
                        <path d="M458.9,550.9v-12.3h8.2v1.8h-6v3.3h5.2v1.8h-5.2v3.4h6.1v1.8h-8.3V550.9z"/>
                        <path d="M473.8,545.9h-2.6v5h-2.3v-12.3h5.3c2.4,0,4.1,1.3,4.1,3.5c0,1.7-1,2.8-2.4,3.3l2.7,5.5h-2.5L473.8,545.9z M471.2,544.1
				h3c1,0,1.8-0.6,1.8-1.8s-0.8-1.8-1.8-1.8h-3V544.1z"/>
                        <path d="M480.2,550.9v-12.3h2.3v12.3H480.2z"/>
                        <path d="M484.4,544.8c0-3.6,2.1-6.3,5.8-6.3c2.2,0,3.8,1,4.8,2.6l-1.7,1.1c-0.6-1.2-1.6-1.9-3-1.9c-2.4,0-3.5,2.1-3.5,4.5
				s1.1,4.5,3.6,4.5c1.4,0,2.4-0.7,3-1.9l1.7,1c-1,1.6-2.6,2.7-4.8,2.7C486.5,551.1,484.4,548.4,484.4,544.8z"/>
                        <path d="M499.5,550.9v-10.4h-4.1v-1.9h10.4v1.9h-4.1v10.4H499.5z"/>
                        <path d="M512.3,538.4c3.9,0,5.9,2.8,5.9,6.3s-2,6.3-5.9,6.3s-5.9-2.8-5.9-6.3S508.5,538.4,512.3,538.4z M512.3,549.2
				c2.4,0,3.6-2.1,3.6-4.5s-1.1-4.5-3.6-4.5c-2.4,0-3.6,2.1-3.6,4.5C508.8,547.1,509.9,549.2,512.3,549.2z"/>
                        <path d="M530.5,538.6v12.3h-2.1l-6.1-8.9l0,0v8.9h-2.2v-12.3h2.4l5.7,8.6l0,0v-8.6H530.5z"/>
                    </g>
                </g>
            </g>
        </svg>


    );
}


export default MapFR;