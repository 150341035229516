import React from "react";
import {Link} from "gatsby"
import Layout from "../../../components/layout"
import MapFR from "../../../components/map-fr";
import HeaderFR from "../../../components/HeaderFR"
import FooterFR from "../../../components/FooterFR";

const FrenchPage = () => (
    <Layout>
        <HeaderFR title={"Bienvenue au connecteur d'emploi dans les soins de santé du Nouveau-Brunswick"}/>
        <div className={"container-xxl"}>
            <div className={"c ac jc"}>
                <h2 className={"tac fw-bold c-green"}>Choisissez votre voie vers l'emploi</h2>
                <Link className={"mt5"} to="/fr/sante/postes/">
                    <button>Naviguer sur tous les postes provinciaux</button>
                </Link>
                <p className={"fw-bold fs-large my4"}>OU</p>
                <h3 className={"tac"}>Sélectionnez votre région préférée sur la carte.</h3>
                <MapFR/>
            </div>
        </div>
        <FooterFR/>
    </Layout>
);

export default FrenchPage;